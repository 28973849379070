<template>
  <div class="advanced-filter">
    <div v-if="filter.filterSet.length == 0">
      <small>No filter applied to this table</small>
    </div>
    <div v-if="filter.filterSet.length > 0">
      <el-row v-for="(filterRow,index) in filter.filterSet" :key="index">
        <el-col :span="4" class="centerCol">
          <i class="el-icon-close el-icon--left" @click="deleteFilter(index)"></i>
          <span v-if="index == 0">where</span>
          <span v-if="index == 1">
            <el-select
              size="small"
              v-model="filterRow.condition"
              placeholder="Select"
              @change="filter.conjunction = filterRow.condition"
            >
              <el-option v-for="item in conditions" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </span>
          <span v-if="index > 1">{{filter.filterSet[1].condition}}</span>
        </el-col>
        <el-col :span="6">
          <el-select
            size="small"
            v-model="filterRow.column"
            placeholder="Select"
            @change="selectField(filterRow,index)"
          >
            <el-option
              v-for="(item,index1) in fieldsNames"
              :key="index1"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select size="small" v-model="filterRow.operator" placeholder="Select">
            <el-option
              v-for="item in filterRow.operations"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="9" class="custom-fiter">
          <template
            v-if="filterRow.filedType == 'input' && filterRow.operator !== 'isEmpty' && filterRow.operator !== 'isNotEmpty'"
          ></template>
          <template v-if="filterRow.filedType == 'date'">
            <el-date-picker
              v-if="showDatePicker(filterRow)"
              key="daterange"
              size="small"
              v-model="filterRow.value1"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="daterange"
              range-separator="-"
              start-placeholder="Start date"
              end-placeholder="End date"
              :unlink-panels="true"
            ></el-date-picker>
            <el-date-picker
              v-else
              size="small"
              key="date"
              v-model="filterRow.value2"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="Pick a day"
            ></el-date-picker>
          </template>
        </el-col>
      </el-row>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-link" @click="addNewFilter">
          <i class="el-icon-plus mr-2"></i>Add Filter
        </button>
        <button
          type="button"
          class="btn btn-link"
          :disabled="filter.filterSet.length == 0"
          @click="$emit('saveFilter',filter)"
        >
          <i class="el-icon-folder-checked mr-2"></i>Save
        </button>
      </div>
      <!-- <div class="col-auto ml-auto">
          <button
            type="button"
            class="btn btn-link"
            :disabled="custom_filter.filterSet.length == 0"
            @click="search"
          >
            <i class="fa fa-search position-left"></i>Search
          </button>
          <button type="button" class="btn btn-link reset-btn" @click="resetSearch">Reset</button>
      </div>-->
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fieldsNames: {
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      conditions: ["And", "Or"],
      filter: {
        conjunction: "And",
        name: "",
        filterSet: []
      }
    };
  },
  methods: {
    addNewFilter() {
      this.filter.filterSet.push({
        column: this.fieldsNames[0].value,
        operator: this.fieldsNames[0].operations[0].value,
        operations: this.fieldsNames[0].operations,
        label: this.fieldsNames[0].label,
        filedType: this.fieldsNames[0].filedType,
        value: null
      });
    },
    deleteFilter(index) {
      this.filter.filterSet.splice(index, 1);
    },
    selectField(filterRow, index) {
      let row = this.fieldsNames.find(
        filter => filter.value == filterRow.column
      );
      this.filter.filterSet[index].operator = row.operations[0].value;
      this.filter.filterSet[index].operations = row.operations;
      this.filter.filterSet[index].filedType = row.filedType;
      this.filter.filterSet[index].label = row.label;
    },
    setDateRangeValue(filterRow) {
      if (filterRow.hasOwnProperty("value1")) {
        filterRow.value = {
          from: filterRow.value1[0],
          to: filterRow.value1[1]
        };
      } else {
        delete filterRow.value;
      }
    },
    setDateValue(filterRow) {
      if (filterRow.hasOwnProperty("value2")) {
        filterRow.value = filterRow.value2;
      } else {
        delete filterRow.value;
      }
    },
    showDatePicker(filterRow) {
      if (filterRow.operator !== "isWithin") {
        this.setDateValue(filterRow);
        return false;
      } else {
        this.setDateRangeValue(filterRow);

        return true;
      }
    }
  }
};
</script>
<style>
.advanced-filter small {
  color: #b5b5b5;
  font-size: 85%;
}
.advanced-filter .btn-link {
  /* font-size: 14px; */
  padding-left: 0;
}
.centerCol {
  height: 32px;
  line-height: 32px;
}
.advanced-filter .el-select .el-input__inner {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-color: #fff !important;
  color: #404040;
}
.advanced-filter .custom-fiter .el-input__inner {
  width: 100%;
  background-color: #f7f7f7;
  border-color: #eaebeb !important;
}
.advanced-filter .centerCol .el-select {
  width: 64%;
}
.advanced-filter .centerCol .el-select .el-input__inner {
  padding-left: 0;
}
.advanced-filter .el-row {
  margin-bottom: 12px;
}
</style>
<template>
  <el-table :data="items" border style="width: 100%">
    <el-table-column prop="sku" label="MID SKU" width="120"></el-table-column>
    <el-table-column prop="parent_asin" label="Parent ASIN" width="120"></el-table-column>
    <el-table-column prop="asin" label="(Child) ASIN" width="120"></el-table-column>

    <el-table-column prop="short_description" label="Item Short Description" width="300"></el-table-column>
    <el-table-column prop="unit_rom" label="Unit ROM" width="120"></el-table-column>

    <el-table-column prop="sales_revenue" label="Sales Revenue" width="120"></el-table-column>
    <el-table-column
      prop="cost_per_single_unit"
      label="All-in Costs (Ads+Fees+Item costs)"
      width="120"
    ></el-table-column>
    <el-table-column prop="units" label="All-in Return on ASIN" width="120"></el-table-column>
    <el-table-column prop="total_item_costs" label="Total Item Costs" width="180"></el-table-column>
    <el-table-column prop="item_costs_of_sales" label="Item Costs % of Sales" width="180"></el-table-column>

    <el-table-column prop="total_ad_spend" label="Total Ad spend" width="120"></el-table-column>
    <el-table-column prop="Aad_spend_of_sales" label="Ad spend % of Sales" width="150"></el-table-column>
    <el-table-column prop="total_amazon_fees" label="Total Amazon Fees" width="100"></el-table-column>
    <el-table-column prop="Fees_of_sales" label="Fees % of Sales" width="150"></el-table-column>
    <el-table-column prop="units_sold" label="Units Sold" width="120"></el-table-column>
  </el-table>
</template>
<script>
export default {
  name: "DataTable",
  props: {
    items: Array,
    pagination: Object,
    loading: Boolean
  }
};
</script>

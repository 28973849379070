<template>
  <div>
    <el-popover
      placement="bottom-start"
      title
      width="600"
      trigger="manual"
      popper-class="filter-popper"
      :append-to-body="true"
      v-model="visible"
    >
      <advanced-filter ref="filter" :fieldsNames="fieldsNames" @saveFilter="saveFilter" />
      <el-button slot="reference" size="small" @click="visible = !visible">
        Filter
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
    </el-popover>
    <el-dialog title="Save filter" :visible.sync="saveFormVisible" width="30%">
      <el-form :model="view" ref="filter" :rules="filterRules">
        <el-form-item label="Please enter filter name" prop="name">
          <el-input size="small" v-model="view.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="saveFormVisible = false">Cancel</el-button>
        <el-button size="small" type="primary" @click="saveView">Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import AdvancedFilter from "./components/AdvancedFilter";
export default {
  name: "Filter",
  components: {
    AdvancedFilter
  },
  data() {
    return {
      visible: false,
      fieldsNames: [
        { label: "Date", value: "date", filedType: "date", operations: [] }
      ],
      operations: [
        {
          label: "is equal",
          value: "=",
          filedTypes: ["input", "number", "date", "integer"]
        },
        {
          label: "is not equal",
          value: "!=",
          filedTypes: ["input", "number", "integer"]
        },
        {
          label: "has any of",
          value: "isAnyOf",
          filedTypes: ["select", "singleSelect", "input"]
        },
        {
          label: "has none of",
          value: "isNoneOf",
          filedTypes: ["select", "singleSelect"]
        },
        {
          label: "greater or equal",
          value: ">=",
          filedTypes: ["number", "date", "integer"]
        },
        {
          label: "less or equal",
          value: "<=",
          filedTypes: ["number", "date", "integer"]
        },
        {
          label: "greater",
          value: ">",
          filedTypes: ["date", "number", "integer"]
        },
        {
          label: "less",
          value: "<",
          filedTypes: ["date", "number", "integer"]
        },
        { label: "is not empty", value: "isNotEmpty", filedTypes: ["input"] },
        { label: "is empty", value: "isEmpty", filedTypes: ["input"] },
        { label: "contains", value: "contains", filedTypes: ["input"] },
        {
          label: "does not contain",
          value: "doesNotContain",
          filedTypes: ["input"]
        },
        { label: "between", value: "isWithin", filedTypes: ["date"] },
        { label: "starts with", value: "startsWith", filedTypes: ["input"] },
        { label: "ends with", value: "endsWith", filedTypes: ["input"] },
        { label: "is", value: "=", filedTypes: ["checkbox"] }
      ],
      saveFormVisible: false,
      view: {
        name: ""
      },
      filterRules: {
        name: {
          required: true,
          message: "Please input view name",
          trigger: "blur"
        }
      }
    };
  },
  methods: {
    setFieldOperations() {
      this.fieldsNames.forEach(field => {
        field.operations = this.operations.filter(operation => {
          return operation.filedTypes.includes(field.filedType);
        });
      });
    },
    saveFilter(value) {
      this.saveFormVisible = true;
    },
    saveView() {
      this.saveFormVisible = false;
      this.$emit("newView", this.view);
      this.view = {
        name: ""
      };
    }
  },
  mounted() {
    this.setFieldOperations();
  }
};
</script>
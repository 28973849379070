<template>
  <div class="content">
    <!-- Page title -->
    <div class="container-fluid">
      <div class="page-header d-print-none">
        <div class="row align-items-center">
          <div class="col">
            <h2 class="page-title">Return on Investment (ROI)</h2>
          </div>
          <div class="col-auto ml-auto"></div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                  :label="view.name"
                  :name="view.name"
                  v-for="(view,index) in views"
                  :key="index"
                ></el-tab-pane>
              </el-tabs>
            </div>
            <div class="col-auto ml-auto mb-2">
              <the-filter @newView="addNewView" />
            </div>
          </div>
          <data-table :items="items" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DataTable from "./components/DataTable";
import TheFilter from "@/components/the-filter/index.vue";
export default {
  name: "ReturnOnInvestment",
  components: {
    DataTable,
    TheFilter
  },
  data() {
    return {
      items: [],
      views: [],
      activeName: ""
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    addNewView(view) {
      this.views.push(view);
      this.activeName = view.name;
    }
  }
};
</script>